header {
  width: 100%;
  margin-bottom: 50px;
}

.navbar-top {
  background: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 8%), 0 2px 10px rgb(0 0 0 / 6%);
}
.navbar-top .navbar-brand img {
  width: 50px;
}

.nexus-logo-container {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 999;
  box-shadow: 0 2px 5px rgb(0 0 0 / 8%), 0 2px 10px rgb(0 0 0 / 6%);
  padding: 0 10px;
  border-radius: 0 0 10px 10px;
}

.nexus-logo {
  font-size: 22px;
  fill: #404040;
  position: relative;
}
