/* montserrat-regular - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('/assets/fonts/monserrat/latin-regular/montserrat-v23-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-bold*/
@font-face {
	font-family: 'Montserrat';
	font-style: bold;
	font-weight: 700;
	src: local(''), /* Modern Browsers */ url('/assets/fonts/monserrat/latin-regular/Montserrat-Bold.ttf') format('truetype');
}

/* montserrat-light*/
@font-face {
	font-family: 'Montserrat';
	font-style: light;
	font-weight: 300;
	src: local(''), url('/assets/fonts/monserrat/latin-regular/Montserrat-Light.ttf') format('truetype');
}

@font-face {
	font-family: text-security-disc;
	src: url('/assets/fonts/text-security-disc.woff');
}

/* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/roboto/latin-regular/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url('/assets/fonts/roboto/latin-regular//roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('/assets/fonts/roboto/latin-regular//roboto-v30-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/assets/fonts/roboto/latin-regular//roboto-v30-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('/assets/fonts/roboto/latin-regular//roboto-v30-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('/assets/fonts/roboto/latin-regular//roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

$enable-rounded: true;
$white: #fff;
$black: #4a4a4a;

$grey: #9b9b9b;
/* $grey: #8a8989; */

$grey-transparent: rgba($grey, 0.19);

$green: #28b32e;
$red: #ff3b2d;
$yellow: #cccc00;

/*  2022 - visionix
main colors------------------------------*/

// orange
$orange: #e5491f;
// blue
$main-blue: #4f0aeb;
// grey
$main-grey: #404040;

/*
Complementary colors------------------------------*/
// oranges
$orange-med: #f0954e;
$orange-light: #f3bda0;

// blues
$violet: #717ace;
$light-blue: #64a8f7;

// greys
$grey-med: #c9c0b1;
$grey-light: #d9d9d9;

/* !!! not an official color
 ----------------------------------------*/
$grey-lightb: #e7e7e7;

$button-red-text: #ffb9ac;

/*$primary: #4F0AEB; */ /* LTG:  #1985A1;  */
$primary-dark: rgb(12, 64, 77);

$min-contrast-ratio: 2.5;

// gradient

// $gradient : rgba(240, 149, 78, 1);
// $gradient2: linear-gradient(317deg, rgba(240,149,78,1) 0%, rgba(229,73,31,0.68) 100%);
// $gradient3: linear-gradient(right, #43c6ac #191654);

// $icon-font-path: "../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
$border-radius-base: 0;
$navbar-height: 60px;
$footer-height: 50px;
$navbar-default-color: $white;
$navbar-default-bg: $white;
$panel-default-heading-bg: $white;
$htmlFontSize: 16px;

$panel-heading-content-height: 3rem;
$panel-heading-content-height-px: 48px;
$panel-heading-vertical-padding: 0.3rem;
$panel-heading-height: $panel-heading-content-height + 2 * $panel-heading-vertical-padding;
$input-height-base: 15px;

// bootstrap variable customization
$primary: $main-blue;
$brand-primary: $primary;

$secondary: #747474;
$brand-secondary: $secondary;

$btn-padding-x: 1.8rem;
$btn-padding-y: 0.6rem;
// $btn-box-shadow:inset 4px 4px 10px rgba(#000, .35), 0 1px 1px rgba($black, .075);
$btn-active-box-shadow: 4px 4px 10px 0 rgba(#000, 0.35); //?? non funziona

$danger: $orange;
$brand-danger: $danger;
