@import '~@angular/material/prebuilt-themes/indigo-pink.css'; //serve per la material, se non si mette la material sbomba

/* You can add global styles to this file, and also import other style files */
@import './app/styles/variable';
@import './app/styles/content';
@import './app/component/header/header.component.scss';

@import '../node_modules/flag-icons/sass/flag-icons.scss';

@import '../node_modules/bootstrap/scss/bootstrap.scss';

// IMPORTANT on angular.json boostrap styles are not included, because first are modified bootstrap variables (on _variable) after here are included bootstrap styles
//Include all of Bootstrap
/*  General
----------------------------------------------------------------------*/

* {
	font-family: 'Montserrat';
}

html,
body {
	min-height: 100vh; //svh si adatta meglio negli smartphone
}

body {
	font-size: 16px;
	position: relative;
	background: url('/assets/images/background.jpg') no-repeat;
	background-size: cover;
	background-position: center;
	// color: $main-grey;
}
body.rds {
	background: url('/assets/images/background_rds.jpg') no-repeat;
	background-size: cover;
	background-position: center;
}
body::before {
	content: ' ';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(310deg, rgba(64, 64, 64, 0.29) 0%, rgba(64, 64, 64, 0.81) 100%);
	// opacity: 0.5;
	z-index: -1;
}

/*  Form
------------------------------------------------------------*/

.form-group {
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.form-control:disabled {
	background-color: #dbdbdb;
	opacity: 0.8;
}

label {
	font-size: 14px;
	font-weight: bold;
}

/*  Container
------------------------------------------------------------*/

.web-container {
	min-width: 100%;
	min-height: 100vh;
	// margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.panel-body {
	padding-top: 0;
	padding-left: 50px;
	padding-right: 50px;
	background-color: #fff;
	border-radius: 0.5em;
}

.modal-container {
	background: rgba(217, 217, 217, 0.45); //il bg me lo da il ::before
	border-radius: 20px;
	padding: 30px 40px;
	backdrop-filter: blur(10px);
	min-height: 70vh;
}

.panel-modal {
	padding: 30px 40px;
}
@media (max-width: 1200px) {
	.modal-container {
		min-height: 50vh;
	}
}

@media (max-width: 575.98px) {
	.modal-container {
		border-radius: 0;
		padding: 35px 30px;
		min-height: 100vh; //svh si adatta meglio negli smartphone, peró con il contenuto che cambia l'altezza bisogna scrollare per adattarlo
		display: flex;
		flex-direction: column;
		align-items: center;
		backdrop-filter: blur(2px);
		@media (min-width: 575.98px) {
			justify-content: center;
		}
	}

	.panel-modal {
		padding: 30px 0px;
	}

	.version-number {
		display: none;
	}
}

/*  material
------------------------------------------------------------*/

@media (max-width: 575.98px) {
	.mat-datepicker-content-touch {
		.mat-button,
		.mat-fab,
		.mat-icon-button,
		.mat-mini-fab,
		.mat-raised-button,
		th,
		td {
			font-size: 20px;
		}
	}

	.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
		width: 55px;
		height: 55px;
		padding: 10px;
	}

	.mat-datepicker-content-touch {
		min-height: 60vh;
		width: 100vw;
	}

	.mat-datepicker-content-touch .mat-datepicker-content-container {
		width: 100% !important;
		height: 100% !important;
	}
}

/* various
------------------------------------------------------------*/

.error-message {
	font-size: 0.8rem;
	color: $red;
	position: absolute;
	bottom: -1.4rem;
}

/* Helpers
------------------------------------------------------------*/

@media (max-width: 575.98px) {
	.p-xs-0 {
		padding: 0;
	}
}

/* LOADER
------------------------------------------------------------*/

.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: -99;
}

.loader-container.show {
	opacity: 1;
	z-index: 99;
}

//## elipsis loading

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid $violet;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $violet transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
